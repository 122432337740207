import React from "react";
import Services from "../../api/Services";
import { Link } from "react-router-dom";
import ins1 from "../../images/instragram/7.jpg";
import ins2 from "../../images/instragram/8.jpg";
import ins3 from "../../images/instragram/9.jpg";
import ins4 from "../../images/instragram/10.jpg";
import ins5 from "../../images/instragram/11.jpg";
import ins6 from "../../images/instragram/12.jpg";

const ServiceSidebar = (props) => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="col-lg-4 col-md-8">
      <div className="blog-sidebar">
        <div className="widget category-widget">
          <h3>Services</h3>
          <ul>
            {Services.slice(0, 6).map((service, Sitem) => (
              <li key={Sitem}>
                <Link
                  onClick={ClickHandler}
                  to={`/service-single/${service.slug}`}
                >
                  {service.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ServiceSidebar;
