import React from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";

const Header = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <header id="header" className={props.hclass}>
      <div className="wpo-site-header">
        <nav
          className="navigation navbar navbar-expand-lg navbar-light"
          style={{ padding: 0 }}
        >
          <div className="container p-0">
            <div className="row align-items-center justify-content-between align-items-center">
              <div
                className="col-lg-3 col-md-5 col-6"
                style={{ width: "auto" }}
              >
                <div className="navbar-header">
                  <Link onClick={ClickHandler} className="navbar-brand" to="/">
                    <img
                      src={props.Logo}
                      alt="logo"
                      style={{ maxWidth: "auto" }}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-1 col-1">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} className="active" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="/service">
                        Service
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/service">
                            Service
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/service-single/Bending"
                          >
                            Bending Service
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/service-single/Cutting"
                          >
                            Cutting Service
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/service-single/Hot%20Dip%20Galvanizing"
                          >
                            Hot Dip Galvanizing Service
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/service-single/Metal%20Fabrication"
                          >
                            Metal Fabrication Service
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/service-single/Electro%20plating"
                          >
                            Electro plating Service
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="/about">
                        About
                      </Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="/team">
                        Team
                      </Link>
                    </li>
                    <li className="menu-item-has-children">
                      {/* <Link onClick={ClickHandler} to="#">
                        Pages
                      </Link> */}
                      {/* <ul className="sub-menu"> */}
                      {/* <li>
                          <Link onClick={ClickHandler} to="/about">
                            about
                          </Link>
                        </li> */}
                      {/* <li>
                          <Link onClick={ClickHandler} to="/shop">
                            Shop
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/product-single/Impact-Drill-Yato-Brand"
                          >
                            Shop Single
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/cart">
                            Cart
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/checkout">
                            Checkout
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/pricing">
                            Pricing
                          </Link>
                        </li> */}
                      {/* <li>
                          <Link onClick={ClickHandler} to="/team">
                            Team
                          </Link>
                        </li> */}
                      {/* <li>
                          <Link onClick={ClickHandler} to="/team-single/1">
                            Team Single
                          </Link>
                        </li> */}
                      {/* <li>
                          <Link onClick={ClickHandler} to="/404">
                            404 Error
                          </Link>
                        </li> */}
                      {/* </ul> */}
                    </li>
                    {/* <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="#">
                        Blog
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/blog">Blog right sidebar</Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/blog-left-sidebar">
                            Blog left sidebar
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/blog-fullwidth">
                            Blog fullwidth
                          </Link>
                        </li>
                        <li className="menu-item-has-children">
                          <Link onClick={ClickHandler} to="/">
                            Blog details
                          </Link>
                          <ul className="sub-menu">
                            <li>
                              <Link onClick={ClickHandler} to="/blog-single/1">
                                Blog details right sidebar
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={ClickHandler}
                                to="/blog-single-left-sidebar/1"
                              >
                                Blog details left sidebar
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={ClickHandler}
                                to="/blog-single-fullwidth/1"
                              >
                                Blog details fullwidth
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      <Link onClick={ClickHandler} to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "auto",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <div className="col-lg-3 col-md-3 col-2  me-5 me-lg-1 me-md-4">
                  <div className="header-right">
                    <div className="close-form">
                      <Link
                        className="theme-btn"
                        to="/contact"
                        style={{ width: "max-content" }}
                      >
                        <i className="fi flaticon-phone-call-1"></i>
                        <span>Contact Us</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block me-0">
                  <MobileMenu />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};

export default connect(mapStateToProps, { removeFromCart })(Header);
