import React from "react";
import { Navigation, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Link } from "react-router-dom";
import hero1 from "../../images/slider/tube-cutting.jpeg";
import hero2 from "../../images/slider/metal-fabrications.jpeg";
import hero4 from "../../images/slider/Hot-Dip-Galvanizing.jpeg";
import hero5 from "../../images/slider/Electroplating-Services.png";
import hero7 from "../../images/slider/lasercutting.jfif";
import PressureVessel from "../../images/slider/Pressure-Vessel.jpeg";
import ColumnVessel from "../../images/slider/Columns-vessels.jpeg";
import Skits from "../../images/slider/skits.jpeg";
import HeatExchanger from "../../images/slider/Heat-Exchanger.jpeg";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};
const Hero = () => {
  return (
    <section className="wpo-hero-slider wpo-hero-style-1">
      <Swiper
        modules={[Navigation, A11y, Autoplay]} // Include Autoplay module
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        speed={1000}
        parallax={true}
        navigation
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <div
            className="slide-inner slide-bg-image"
            style={{ backgroundImage: `url(${hero7})` }}
          >
            <div className="container-fluid">
              <div className="slide-content">
                <div className="slide-title">
                  <h2>
                    Innovative<span> Laser Cutting </span>Technology
                  </h2>
                </div>
                <div className="slide-title-sub">
                  <p>
                    Harnessing the power of lasers for unparalleled precision.
                  </p>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="slide-inner slide-bg-image"
            style={{ backgroundImage: `url(${hero1})` }}
          >
            <div className="container-fluid">
              <div className="slide-content">
                <div className="slide-title">
                  <h2>
                    <span>Tube </span>Cutting
                  </h2>
                </div>
                <div className="slide-title-sub">
                  <p>
                    Our cutting services provide the highest quality cutting
                    solutions.
                  </p>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="slide-inner slide-bg-image"
            style={{ backgroundImage: `url(${hero2})` }}
          >
            <div className="container-fluid">
              <div className="slide-content">
                <div className="slide-title">
                  <h2>
                    <span>Metal </span>Fabrications
                  </h2>
                </div>
                <div className="slide-title-sub">
                  <p>
                    Our fabrication services produce high-quality metal products
                    that meet your exact specifications and exceed your
                    expectations.
                  </p>
                </div>
                <div className="clearfix"></div>
                <div className="slide-btns">
                  <Link
                    onClick={ClickHandler}
                    to="/about"
                    className="theme-btn"
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="slide-inner slide-bg-image"
            style={{ backgroundImage: `url(${hero4})` }}
          >
            <div className="container-fluid">
              <div className="slide-content">
                <div className="slide-title">
                  <h2>
                    Comprehensive <span>Hot Dip</span> Galvanizing
                  </h2>
                </div>
                <div className="slide-title-sub">
                  <p>
                    Ensuring longevity and corrosion resistance with our
                    superior galvanizing process.
                  </p>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="slide-inner slide-bg-image"
            style={{ backgroundImage: `url(${hero5})` }}
          >
            <div className="container-fluid">
              <div className="slide-content">
                <div className="slide-title">
                  <h2>
                    Expert <span>Electroplating </span>Services.
                  </h2>
                </div>
                <div className="slide-title-sub">
                  <p>
                    Providing high-quality electroplating for a perfect finish.
                  </p>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="slide-inner slide-bg-image"
            style={{ backgroundImage: `url(${PressureVessel})` }}
          >
            <div className="container-fluid">
              <div className="slide-content">
                <div className="slide-title">
                  <h2>
                    Quality <span> Presesure </span>Vessels
                  </h2>
                </div>
                <div className="slide-title-sub">
                  <p>
                    We offer a wide range of pressure vessels that meet
                    international standards.
                  </p>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="slide-inner slide-bg-image"
            style={{ backgroundImage: `url(${ColumnVessel})` }}
          >
            <div className="container-fluid">
              <div className="slide-content">
                <div className="slide-title">
                  <h2>
                    Quality <span> Column </span>Vessels
                  </h2>
                </div>
                <div className="slide-title-sub">
                  <p>
                    Columns Vessels for different applications are available.
                  </p>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="slide-inner slide-bg-image"
            style={{ backgroundImage: `url(${HeatExchanger})` }}
          >
            <div className="container-fluid">
              <div className="slide-content">
                <div className="slide-title">
                  <h2>
                    Quality <span> Heat Exchanger </span>Systems
                  </h2>
                </div>
                <div className="slide-title-sub">
                  <p>
                    We offer a wide range of heat exchangers that meet
                    international standards.
                  </p>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="slide-inner slide-bg-image"
            style={{ backgroundImage: `url(${Skits})` }}
          >
            <div className="container-fluid">
              <div className="slide-content">
                <div className="slide-title">
                  <h2>
                    Quality <span> Piping and Skid</span> Systems
                  </h2>
                </div>
                <div className="slide-title-sub">
                  <p>
                    Designing and fabricating efficient piping and skid systems.
                  </p>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        ...
      </Swiper>
    </section>
  );
};

export default Hero;
