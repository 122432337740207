import React, { Fragment } from "react";
import Slider from "react-slick";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Projects from "../../api/projects";

import Logo from "../../images/logo-2.svg";

const ProjectSinglePage = (props) => {
  const { slug } = useParams();

  const ProjectSingle = Projects.find((item) => item.slug === slug);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <PageTitle pageTitle={ProjectSingle.title} pagesub={"Project"} />
      <section className="wpo-project-single-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="wpo-project-single-wrap">
                <div className="wpo-project-single-item">
                  <div className="row align-items-center">
                    <div className="col-lg-7">
                      <div className="wpo-project-single-title">
                        <h3>{ProjectSingle.title}</h3>
                      </div>
                      <p>{ProjectSingle.p1}</p>
                      <p>{ProjectSingle.p2}</p>
                    </div>
                    <div className="col-lg-5">
                      <div className="wpo-project-single-content-des-right">
                        <ul>
                          <li>
                            Location :<span>{ProjectSingle.location}</span>
                          </li>
                          <li>
                            Client :<span>wpOceans</span>
                          </li>
                          <li>
                            Architect :<span>{ProjectSingle.architect}</span>
                          </li>
                          <li>
                            Project Type :<span>{ProjectSingle.type}</span>
                          </li>
                          <li>
                            Duration :<span>{ProjectSingle.duration}</span>
                          </li>
                          <li>
                            Completion :<span>15 Dec 2024</span>
                          </li>
                          <li>
                            Share :<span>Industrial, Business</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="wpo-project-single-main-img">
                    <Slider {...settings}>
                      {ProjectSingle.slidesImg.map((item, index) => (
                        <img src={item} alt="img" key={index} />
                      ))}
                    </Slider>
                  </div>
                </div>
                <div className="wpo-project-single-item list-widget">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="wpo-project-single-title">
                        <h3>Our Strategies</h3>
                      </div>
                      <p>{ProjectSingle.Strategies}</p>
                      <ul>
                        {ProjectSingle.StrategiesList.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <div className="wpo-project-single-item-quote">
                        <p>{ProjectSingle.Quote}</p>
                        <span>
                          {ProjectSingle.QuoteAuthor} -{" "}
                          <span>{ProjectSingle.QuotePosition}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wpo-project-single-item">
                  <div className="wpo-project-single-title">
                    <h3>Our approach</h3>
                  </div>
                  <p>{ProjectSingle.ourapproach}</p>
                </div>

                <div className="wpo-project-single-item list-widget">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="wpo-project-single-title">
                        <h3>Resieved goals</h3>
                      </div>
                      <ul>
                        {ProjectSingle.resievedgoals.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-lg-6 list-widget-s">
                      <div className="wpo-project-single-title">
                        <h3>Results</h3>
                      </div>
                      <ul>
                        {ProjectSingle.results.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="wpo-project-single-item">
                  <div className="wpo-project-single-title">
                    <h3>Related Projects</h3>
                  </div>
                  <div className="wpo-project-area-s2 single">
                    <div className="row align-items-center">
                      <div className="wpo-project-wrap">
                        <div className="row">
                          {Projects.slice(0, 3).map((project, i) => (
                            <div className="col-lg-4 col-md-6 col-12" key={i}>
                              <div className="grid">
                                <div className="images">
                                  <img src={project.pimg1} alt="img" />
                                </div>
                                <div className="text">
                                  <span>{project.subtitle}</span>
                                  <h2>{project.title}</h2>
                                  <Link
                                    onClick={ClickHandler}
                                    to={`/project-single/${project.slug}`}
                                    className="theme-btn"
                                  >
                                    <i className="ti-arrow-right"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ProjectSinglePage;
