

const pricing = [
    {
        id:'1',
        type: "Basic",
        price: "$120",
        description: "Interior represents the way to feel happy and complete."
    },
    {
        id: '2',
        type: "Premium",
        price: "$210",
        description: "Interior represents the way to feel happy and complete."
    },
    {
        id: '3',
        type: "Advanced",
        price: "$373",
        description: "Interior represents the way to feel happy and complete."
    }
]

export default pricing;