import simg1 from "../images/service/lasser-cutting.jfif";
import simg2 from "../images/service/tube-cutting.jpeg";
import simg3 from "../images/service/hot-dip-galvinizing.jpeg";
import simg4 from "../images/service/metal-fabrication.jpg";
import simg5 from "../images/service/electro-plating.jpeg";

import sSimg1 from "../images/service-single/lasser-cutting1.jfif";
import sSimg2 from "../images/service-single/tube-cutting2.jpeg";
import sSimg3 from "../images/service-single/hotdipgalvining.jpeg";
import sSimg4 from "../images/service-single/metalfabricationmain.jpeg";
import sSimg5 from "../images/service-single/electroplatingmain.jpeg";

import bending1 from "../images/service-single/lasser-cutting2.jpg";
import bending2 from "../images/service-single/tube-cutting3.jpeg";
import tubecutting1 from "../images/service-single/cutting1.jpg";
import tubecutting2 from "../images/service-single/cutting2.jpg";
import hotdip1 from "../images/service-single/hotdipgalvining2.jpeg";
import hotdip2 from "../images/service-single/hotdipgalvining3.jpeg";
import metal1 from "../images/service-single/metalfabrication3.jpeg";
import metal2 from "../images/service-single/metalfabrications4.jpeg";
import electro1 from "../images/service-single/electroplating1.jpeg";
import electro2 from "../images/service-single/electroplating2.jpeg";

const Services = [
  {
    Id: "01",
    sImg: simg1,
    sSImg: sSimg1,
    support1: bending1,
    support2: bending2,
    title: "Laser Cutting",
    slug: "laser-cutting",
    description:
      "Precision laser cutting solutions for intricate designs and high-quality metal fabrication.",
    longContent:
      "Laser cutting is an essential process in modern fabrication, providing unmatched precision and efficiency for metal cutting tasks. At Lisha Engineering, we utilize state-of-the-art laser cutting technology to deliver high-quality results for a variety of industries. Our advanced equipment ensures clean, precise cuts with minimal material waste, making it ideal for both intricate designs and large-scale production. Whether you require custom parts, complex patterns, or large structural components, our laser cutting services provide the accuracy and flexibility needed to bring your projects to life. With a commitment to quality and precision, we guarantee that every cut meets the highest industry standards.",

    smallContent:
      "We offer precise laser cutting services for intricate designs and large-scale fabrication, ensuring high-quality results with minimal material waste.",

    capabilityDescriptions:
      "Our laser cutting capabilities cater to a wide range of industries, offering precision and efficiency for even the most complex designs. At Lisha Engineering, we combine cutting-edge technology with expert craftsmanship to deliver superior results. We handle a variety of materials and thicknesses, providing tailored solutions to meet the unique needs of each project. From small, intricate parts to large structural components, our laser cutting services ensure precision, accuracy, and quality, making us a trusted partner in metal fabrication.",

    capabilityPoints: [
      "High precision cutting with minimal material waste",
      "Ability to handle a variety of materials and thicknesses",
      "Fast turnaround times with consistent high-quality results",
      "Compliance with industry standards and specifications",
      "Custom solutions tailored to project-specific requirements",
    ],

    approach:
      "At Lisha Engineering, our laser cutting approach is driven by innovation, quality, and customer satisfaction. We begin by thoroughly understanding the needs of your project, ensuring that our laser cutting process aligns with your exact specifications. Using the latest laser cutting technology, we achieve precision and consistency across all cuts, whether for intricate patterns or large components. Our team of skilled technicians ensures that every project meets our rigorous quality standards, providing reliable results that add value and performance to your metalwork.",

    process: [
      "Consultation to define project specifications",
      "Material selection and preparation for cutting",
      "Precision laser cutting for accurate results",
      "Quality inspection to ensure cut integrity",
      "Final delivery with full documentation",
    ],
  },
  {
    Id: "02",
    sImg: simg2,
    sSImg: sSimg2,
    support1: tubecutting1,
    support2: tubecutting2,
    title: "Tube Cutting",
    slug: "Tube Cutting",
    description:
      "High-precision tube cutting services for diverse fabrication needs, ensuring accuracy and quality in every cut.",
    longContent:
      "Tube cutting plays a vital role in metal fabrication, offering precise and efficient solutions for cutting tubes of various materials and dimensions. At Lisha Engineering, we specialize in high-precision tube cutting services that cater to a wide range of industries. Whether you need complex geometries, straight cuts, or beveled edges, our advanced tube cutting technology ensures accuracy and consistency with every cut. We handle both small-batch and large-scale production, delivering reliable results that meet the most stringent quality standards. Our tube cutting capabilities ensure a clean finish, optimal material utilization, and adherence to project-specific requirements, making us the ideal partner for all your fabrication needs.",

    smallContent:
      "We provide precise tube cutting services for various materials and sizes, ensuring accuracy and quality for both simple and complex projects.",

    capabilityDescriptions:
      "Our tube cutting capabilities are designed to meet the needs of diverse industries, from automotive to construction. At Lisha Engineering, we leverage the latest cutting technology to offer tailored solutions for every project. We handle tubes of different materials, shapes, and sizes, ensuring high-quality results with minimal waste. Whether it’s for intricate designs or heavy-duty structural components, our tube cutting services deliver precision and efficiency, helping you achieve your project goals with confidence.",

    capabilityPoints: [
      "Precision cutting for a variety of tube sizes and materials",
      "Ability to produce complex geometries and angles",
      "Minimal material waste with optimal accuracy",
      "Fast lead times without compromising quality",
      "Customized solutions for specific project requirements",
    ],

    approach:
      "At Lisha Engineering, our approach to tube cutting combines cutting-edge technology with expert craftsmanship. We begin with a thorough consultation to understand your project requirements, ensuring that our tube cutting process aligns with your design and performance expectations. Using the latest equipment, we execute precision cuts with minimal material waste, achieving both complex and simple designs with high accuracy. Our team closely monitors the process to ensure that every cut meets our stringent quality standards, providing you with reliable results that enhance the overall quality and performance of your project.",

    process: [
      "Consultation to understand project specifications",
      "Material preparation and measurement for precision cutting",
      "Tube cutting with high accuracy and minimal waste",
      "Quality inspection to ensure dimensional accuracy",
      "Final delivery with full documentation",
    ],
  },
  {
    Id: "03",
    sImg: simg3,
    sSImg: sSimg3,
    support1: hotdip1,
    support2: hotdip2,
    title: "Hot Dip Galvanizing",
    slug: "Hot Dip Galvanizing",
    description:
      "Reliable hot dip galvanizing to protect metal structures with a durable, corrosion-resistant coating.",
    longContent:
      "Hot Dip Galvanizing is a critical process in fabrication that provides long-lasting protection against corrosion for metal structures. At Lisha, we excel in offering top-quality hot dip galvanizing services, ensuring that your projects are not only durable but also meet the highest industry standards. Our state-of-the-art facilities and skilled team ensure that each piece is uniformly coated, providing robust protection and extending the life of your metal products. Whether it’s for large structural components or smaller intricate parts, our hot dip galvanizing process guarantees superior corrosion resistance, making your metalwork capable of withstanding the harshest environments.",

    smallContent:
      "We provide expert hot dip galvanizing services to protect your metal structures with a durable, corrosion-resistant coating, ensuring longevity and reliability.",

    capabilityDescriptions:
      "Our hot dip galvanizing capabilities are designed to meet the diverse needs of various industries, from construction to manufacturing. With advanced technology and a dedicated team, Lisha delivers consistent, high-quality results that enhance the durability and lifespan of metal products. We handle projects of all sizes, offering tailored solutions that meet the specific requirements of each client. Our commitment to excellence ensures that every piece we galvanize is protected against corrosion, providing long-term value and performance.",

    capabilityPoints: [
      "Uniform and durable coating for superior corrosion resistance",
      "Ability to handle large and complex structures",
      "Quick turnaround times with high-quality results",
      "Compliance with industry standards and specifications",
      "Customized solutions to meet specific project needs",
    ],

    approach:
      "At Lisha, our approach to hot dip galvanizing is centered around quality, precision, and customer satisfaction. We start by thoroughly understanding the specific needs of your project, ensuring that our process aligns with your requirements. Utilizing advanced technology and industry-leading practices, we meticulously prepare each piece for galvanizing, ensuring optimal adhesion and coating consistency. Our experienced team carefully monitors the entire process to guarantee that every piece meets our stringent quality standards. We believe in delivering not just a service, but a solution that adds value to your project, providing long-term protection and durability.",

    process: [
      "Consultation to understand project requirements",
      "Surface preparation for optimal coating adhesion",
      "Hot dip galvanizing process for uniform coating",
      "Quality inspection to ensure coating integrity",
      "Final delivery with comprehensive documentation",
    ],
  },
  {
    Id: "04",
    sImg: simg4,
    sSImg: sSimg4,
    support1: metal1,
    support2: metal2,
    title: "Metal Fabrication",
    slug: "Metal Fabrication",
    description:
      "Comprehensive metal fabrication services, transforming raw materials into high-quality, custom-built components.",
    longContent:
      "At Lisha, our metal fabrication services are designed to meet the diverse needs of our clients. From concept to completion, we take pride in delivering high-quality, custom-built metal components that are crafted with precision and expertise. Our state-of-the-art facilities and skilled team allow us to handle projects of all sizes, ensuring that each piece is manufactured to exact specifications. Whether you require structural elements, custom metalwork, or specialized fabrication, Lisha is committed to delivering solutions that are both durable and reliable. With a focus on innovation, quality, and efficiency, we transform raw materials into finished products that exceed expectations.",

    smallContent:
      "Lisha offers top-tier metal fabrication services, delivering custom-built components with precision and expertise to meet your unique needs.",

    capabilityDescriptions:
      "Lisha’s metal fabrication capabilities are second to none. Our advanced technology and skilled workforce enable us to provide a wide range of fabrication services, from cutting and bending to welding and assembly. We are equipped to handle projects of any complexity, ensuring that every component is crafted with the utmost attention to detail. Our commitment to quality and innovation drives us to continuously improve our processes, ensuring that we deliver the best possible results for our clients.",

    capabilityPoints: [
      "Custom metal component fabrication",
      "Precision cutting, bending, and welding",
      "Assembly and finishing services",
      "Handling projects of all sizes and complexities",
      "Utilizing advanced technology for superior results",
    ],

    approach:
      "At Lisha, our approach to metal fabrication is rooted in collaboration, innovation, and quality. We work closely with our clients to understand their specific requirements, ensuring that every project is tailored to meet their needs. Our team of experts leverages the latest technology and industry best practices to deliver high-quality, cost-effective solutions. We are committed to continuous improvement, always seeking new ways to enhance our processes and deliver better results for our clients.",

    process: [
      "Initial Consultation: Understanding the client’s needs and project requirements.",
      "Design and Planning: Creating detailed designs and planning the fabrication process.",
      "Material Selection: Choosing the best materials for the job.",
      "Fabrication: Cutting, bending, welding, and assembling components with precision.",
      "Quality Control: Conducting thorough inspections to ensure high standards are met.",
      "Final Delivery: Delivering the completed project on time and within budget.",
    ],
  },
  {
    Id: "04",
    sImg: simg5,
    sSImg: sSimg5,
    support1: electro1,
    support2: electro2,
    title: "Electro plating",
    slug: "Electro plating",
    description:
      "Expert electroplating to enhance durability and corrosion resistance, ensuring a perfect finish.",

    longContent:
      "Electroplating at Lisha is a sophisticated process designed to enhance the functionality and appearance of metal components. Our electroplating services provide a high-quality finish that improves durability, corrosion resistance, and aesthetic appeal. By using advanced electroplating techniques, we apply a precise metal coating to your parts, ensuring a perfect, long-lasting finish that meets your specifications. Our state-of-the-art equipment and experienced team ensure that every project benefits from superior quality and performance.",
    smallContent:
      "Expert electroplating services that enhance durability and appearance with high-quality, precision coatings.",
    capabilityDescriptions:
      "At Lisha, our electroplating capabilities include a range of metal coatings that improve both the performance and visual appeal of your components. We use advanced techniques and equipment to ensure a flawless finish and enhanced protection against corrosion and wear.",
    capabilityPoints: [
      "High-quality metal coatings",
      "Improved corrosion resistance",
      "Enhanced component durability",
      "Precision and uniformity in plating",
      "Customizable finishes for various applications",
    ],
    approach:
      "Our approach to electroplating combines cutting-edge technology with meticulous craftsmanship. We start by thoroughly preparing the components to ensure a clean surface for optimal adhesion. Our skilled technicians then apply the electroplating solution with precision, followed by rigorous quality checks to guarantee that every piece meets our high standards. This approach ensures that your components receive a durable and aesthetically pleasing finish.",
    process: [
      "Surface Preparation: Thorough cleaning and preparation of components to ensure optimal adhesion of the plating.",
      "Plating Application: Using advanced electroplating techniques to apply a precise metal coating.",
      "Quality Inspection: Rigorous inspection to ensure uniformity, adherence, and overall quality of the electroplating.",
      "Finishing Touches: Any additional treatments or finishes required to meet specific project needs.",
      "Delivery: Timely and secure delivery of the finished components, ready for integration into your project.",
    ],
  },
];

export default Services;
