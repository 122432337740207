import pimg1 from "../images/project/electroplating-projects.jpg";
import pimg2 from "../images/project/metal-projects.jpg";
import pimg3 from "../images/project/hot-dip-projects.jpg";
import pimg4 from "../images/project/bending-projects.jpg";
import pimg5 from "../images/project/cutting-projects.jpg";
import pimg6 from "../images/project/piping-skitting-projects.jpg";

// detils slides image
import b1 from "../images/project-single/b1.jpg";
import b2 from "../images/project-single/b2.jpg";
import b3 from "../images/project-single/b3.jpg";
import c1 from "../images/project-single/c1.jpg";
import c2 from "../images/project-single/c2.jpg";
import c3 from "../images/project-single/c3.jpg";
import m1 from "../images/project-single/m1.jpg";
import m2 from "../images/project-single/m2.jpg";
import m3 from "../images/project-single/m3.jpg";
import hd1 from "../images/project-single/hd1.jpg";
import hd2 from "../images/project-single/hd2.jpg";
import hd3 from "../images/project-single/hd3.jpg";
import ps1 from "../images/project-single/ps1.jpg";
import ps2 from "../images/project-single/ps2.jpg";
import ps3 from "../images/project-single/ps3.jpg";
import e1 from "../images/project-single/e1.jpg";
import e2 from "../images/project-single/e2.jpg";
import e3 from "../images/project-single/e3.jpg";

const Projects = [
  {
    id: "1",
    title: "Electroplating Engineering",
    subtitle: "FACTORY / INDUSTRY",
    slug: "1",
    pimg1: pimg1,
    slidesImg: [e1, e2, e3],
    date: "Feb 2019",
    location: "Mumbai, India",
    type: "Industrial Engineering",
    service: "Electroplating",
    duration: "12 Years",
    add: "Goregaon East, Mumbai, Maharashtra 400063",
    architect: "Ravi Sharma",
    share: "Industrial, Manufacturing",
    fax: "022 1234 5678",
    p1: "Our electroplating engineering services in Mumbai are designed to deliver high-quality metal coatings that enhance both the durability and appearance of industrial components. Utilizing advanced electroplating techniques, we ensure a precise application of metal coatings, improving corrosion resistance and extending the lifespan of parts. Our state-of-the-art facilities and skilled technicians work meticulously to meet the exacting standards required for various industrial applications.",
    p2: "Top-notch electroplating with advanced technology for enhanced durability and visual appeal.",
    Strategies:
      "Employing cutting-edge electroplating technology to achieve superior quality finishes.",
    StrategiesList: [
      "Utilize advanced electroplating equipment",
      "Ensure thorough surface preparation",
      "Apply precision metal coatings",
      "Conduct rigorous quality inspections",
      "Offer customizable plating solutions",
    ],
    Quote:
      "Electroplating is more than just a finish; it's a vital part of ensuring the longevity and performance of industrial components.",
    QuoteAuthor: "Ravi Sharma",
    QuotePosition: "Chief Engineer",
    ourapproach:
      "We focus on integrating advanced technology with meticulous craftsmanship to provide a durable and aesthetically pleasing finish. Our process starts with thorough preparation of components, followed by precision electroplating and rigorous quality control.",
    resievedgoals: [
      "Enhance component durability",
      "Improve corrosion resistance",
      "Achieve precise and uniform coatings",
      "Meet specific project requirements",
      "Deliver high-quality finishes",
    ],
    results: [
      "Long-lasting metal coatings",
      "Increased component lifespan",
      "Enhanced visual appeal",
      "Consistent and reliable quality",
      "Tailored solutions for diverse applications",
    ],
  },
  {
    id: "2",
    title: "Metal Engineering",
    subtitle: "FACTORY / INDUSTRY",
    slug: "2",
    pimg1: pimg2,
    slidesImg: [m1, m2, m3],
    date: "Feb 2019",
    location: "Bengaluru, India",
    type: "Industrial Engineering",
    service: "Metal Fabrication",
    duration: "12 Years",
    add: "Whitefield, Bengaluru, Karnataka 560066",
    architect: "Amit Patel",
    share: "Industrial, Construction",
    fax: "080 9876 5432",
    p1: "Our metal engineering services in Bengaluru cover a wide range of fabrication needs, from custom metal components to large-scale industrial structures. We employ advanced techniques and equipment to handle various metals with precision, ensuring that every piece meets the highest standards of quality and durability. Our team of experts is committed to delivering solutions that cater to the specific requirements of each project.",
    p2: "Comprehensive metal fabrication services with precision and quality.",
    Strategies:
      "Leveraging advanced metalworking techniques and equipment to deliver precise and durable components.",
    StrategiesList: [
      "Use state-of-the-art metal fabrication technology",
      "Execute precision cutting and welding",
      "Custom design solutions for various needs",
      "Ensure strict quality control",
      "Adapt to specific project requirements",
    ],
    Quote:
      "Metal fabrication is the backbone of industrial innovation, turning raw materials into functional and reliable components.",
    QuoteAuthor: "Amit Patel",
    QuotePosition: "Lead Fabrication Engineer",
    ourapproach:
      "We integrate the latest technology with expert craftsmanship to ensure that our metal fabrication services meet the highest standards. From detailed planning and design to precise fabrication and quality control, our approach is focused on delivering exceptional results.",
    resievedgoals: [
      "Provide high-quality metal components",
      "Ensure precise fabrication and design",
      "Meet diverse industrial requirements",
      "Deliver on time and within budget",
      "Maintain high standards of quality",
    ],
    results: [
      "Durable and precise metal components",
      "High-quality fabrication results",
      "Tailored solutions for various applications",
      "Efficient project execution",
      "Reliable and functional products",
    ],
  },
  {
    id: "3",
    title: "Hot Dip Engineering",
    subtitle: "FACTORY / INDUSTRY",
    slug: "3",
    pimg1: pimg3,
    slidesImg: [hd1, hd2, hd3],
    date: "Feb 2019",
    location: "Delhi, India",
    type: "Industrial Engineering",
    service: "Hot Dip Galvanizing",
    duration: "12 Years",
    add: "Dwarka Sector 21, Delhi 110075",
    architect: "Sanjay Verma",
    share: "Industrial, Infrastructure",
    fax: "011 5678 1234",
    p1: "Our hot dip galvanizing services in Delhi offer robust protection for metal structures, providing a durable and corrosion-resistant coating. This process involves immersing metal components in molten zinc, which forms a strong, protective layer. Our facility uses advanced technology to ensure a high-quality finish that extends the life of your components and maintains their structural integrity.",
    p2: "Durable hot dip galvanizing for enhanced corrosion resistance and long-lasting protection.",
    Strategies:
      "Implementing high-temperature galvanizing techniques to provide superior corrosion protection for metal structures.",
    StrategiesList: [
      "Use advanced hot dip galvanizing equipment",
      "Ensure thorough immersion in molten zinc",
      "Apply uniform and consistent coatings",
      "Conduct regular quality assessments",
      "Offer customized galvanizing solutions",
    ],
    Quote:
      "Hot dip galvanizing is essential for protecting metal components from the harshest environmental conditions, ensuring their longevity and reliability.",
    QuoteAuthor: "Sanjay Verma",
    QuotePosition: "Senior Galvanizing Specialist",
    ourapproach:
      "Our hot dip galvanizing approach focuses on using the latest technology to provide a protective coating that withstands environmental challenges. We emphasize thorough immersion and quality control to ensure every component meets high standards.",
    resievedgoals: [
      "Provide superior corrosion protection",
      "Extend the lifespan of metal components",
      "Ensure uniform coating application",
      "Meet specific protective requirements",
      "Deliver consistent quality and performance",
    ],
    results: [
      "Enhanced corrosion resistance",
      "Extended component lifespan",
      "Reliable protective coating",
      "Consistent and high-quality finishes",
      "Effective against environmental wear",
    ],
  },
  {
    id: "4",
    title: "Bending Engineering",
    subtitle: "FACTORY / INDUSTRY",
    slug: "4",
    pimg1: pimg4,
    slidesImg: [b1, b2, b3],
    date: "Feb 2019",
    location: "Pune, India",
    type: "Industrial Engineering",
    service: "Metal Bending",
    duration: "12 Years",
    add: "Hinjewadi, Pune, Maharashtra 411057",
    architect: "Nisha Joshi",
    share: "Industrial, Structural",
    fax: "020 2345 6789",
    p1: "In Pune, our bending engineering services are focused on creating precise and complex metal shapes for a wide range of applications. Using advanced bending equipment and techniques, we ensure that every bend is accurate and meets the highest quality standards. Our skilled team is equipped to handle various metals and thicknesses, providing solutions that meet the specific needs of each project.",
    p2: "Precise metal bending for complex shapes and structural integrity.",
    Strategies:
      "Employing advanced bending technologies to achieve accurate and consistent metal shapes for various applications.",
    StrategiesList: [
      "Utilize modern bending machinery",
      "Ensure precision in every bend",
      "Handle various metal types and thicknesses",
      "Maintain strict quality control",
      "Customize bending solutions for specific needs",
    ],
    Quote:
      "Precision in metal bending is crucial for creating complex shapes that fit perfectly into your project requirements.",
    QuoteAuthor: "Nisha Joshi",
    QuotePosition: "Lead Bending Specialist",
    ourapproach:
      "Our approach to metal bending focuses on precision and consistency. We use state-of-the-art machinery and skilled technicians to ensure that each bend meets exact specifications and quality standards.",
    resievedgoals: [
      "Achieve precise bending results",
      "Handle diverse metal types",
      "Ensure structural integrity",
      "Customize solutions for specific projects",
      "Maintain high-quality standards",
    ],
    results: [
      "Accurate and reliable bends",
      "Enhanced structural performance",
      "Customizable bending solutions",
      "Consistent quality and precision",
      "Efficient project execution",
    ],
  },
  {
    id: "5",
    title: "Cutting Engineering",
    subtitle: "FACTORY / INDUSTRY",
    slug: "5",
    pimg1: pimg5,
    slidesImg: [c1, c2, c3],
    date: "Feb 2019",
    location: "Chennai, India",
    type: "Industrial Engineering",
    service: "Metal Cutting",
    duration: "12 Years",
    add: "Omr, Chennai, Tamil Nadu 600100",
    architect: "Suresh Kumar",
    share: "Industrial, Manufacturing",
    fax: "044 6789 1234",
    p1: "Our cutting engineering services in Chennai provide precise and efficient metal cutting solutions for various industrial applications. Utilizing advanced cutting technology, we ensure clean and accurate cuts that meet your exact specifications. Our skilled technicians work to deliver high-quality results, whether for intricate designs or large-scale projects.",
    p2: "Efficient and precise metal cutting for various industrial needs.",
    Strategies:
      "Utilizing advanced cutting technology to deliver precise and clean metal cuts for diverse applications.",
    StrategiesList: [
      "Employ state-of-the-art cutting machinery",
      "Ensure accuracy and precision in cuts",
      "Handle a range of metal types and sizes",
      "Implement strict quality checks",
      "Provide solutions for complex cutting requirements",
    ],
    Quote:
      "Precision cutting is essential for achieving exact dimensions and clean edges, crucial for high-quality fabrication.",
    QuoteAuthor: "Suresh Kumar",
    QuotePosition: "Cutting Operations Manager",
    ourapproach:
      "We focus on precision and efficiency in our cutting operations, using advanced technology and skilled personnel to achieve exact dimensions and high-quality results.",
    resievedgoals: [
      "Provide accurate and clean cuts",
      "Handle various metal types and sizes",
      "Ensure efficient and timely delivery",
      "Meet specific project requirements",
      "Maintain high-quality standards",
    ],
    results: [
      "Clean and precise metal cuts",
      "High-quality fabrication results",
      "Efficient project execution",
      "Customizable cutting solutions",
      "Consistent accuracy and reliability",
    ],
  },
  {
    id: "6",
    title: "Piping & Skidding Engineering",
    subtitle: "FACTORY / INDUSTRY",
    slug: "6",
    pimg1: pimg6,
    slidesImg: [ps1, ps2, ps3],
    date: "Feb 2019",
    location: "Hyderabad, India",
    type: "Industrial Engineering",
    service: "Piping and Skid Systems",
    duration: "12 Years",
    add: "Gachibowli, Hyderabad, Telangana 500032",
    architect: "Anil Rao",
    share: "Industrial, Utilities",
    fax: "040 1234 5678",
    p1: "Our piping and skidding engineering services in Hyderabad offer comprehensive solutions for designing and installing piping systems and skids. We use advanced technology and methods to ensure that all systems are efficient, reliable, and meet industry standards. Our team is dedicated to delivering high-quality, tailored solutions that address the specific needs of your industrial projects.",
    p2: "Comprehensive solutions for designing and installing piping systems and skids.",
    Strategies:
      "Applying advanced engineering techniques to design and install efficient piping and skidding systems for various industrial needs.",
    StrategiesList: [
      "Design customized piping systems",
      "Implement effective skidding solutions",
      "Use advanced technology for installation",
      "Ensure adherence to industry standards",
      "Provide ongoing support and maintenance",
    ],
    Quote:
      "Effective piping and skidding systems are crucial for optimizing industrial processes and ensuring operational efficiency.",
    QuoteAuthor: "Anil Rao",
    QuotePosition: "Piping & Skidding Engineer",
    ourapproach:
      "Our approach focuses on designing and implementing effective piping and skidding systems that enhance operational efficiency and reliability. We combine advanced technology with expert engineering to meet the specific needs of each project.",
    resievedgoals: [
      "Design efficient piping systems",
      "Implement reliable skidding solutions",
      "Meet industry standards and requirements",
      "Provide customized solutions",
      "Ensure high-quality installation and support",
    ],
    results: [
      "Optimized piping and skidding systems",
      "Reliable and efficient installations",
      "Customized solutions for diverse needs",
      "Adherence to industry standards",
      "Enhanced operational efficiency",
    ],
  },
];

export default Projects;
