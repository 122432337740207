import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Hero2 from "../../components/hero2/Hero2";
import About2 from "../../components/about2/about2";
import PartnersSecion from "../../components/PartnersSecion/PartnersSecion";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import ProjectSection2 from "../../components/ProjectSection2/ProjectSection2";
import FunFact from "../../components/FunFact/FunFact";
import TeamSection from "../../components/TeamSection/TeamSection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/logolish.svg";
import TestimonialSection from "../../components/TestimonialSection/TestimonialSection";

const HomePage2 = () => {
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-4"} Logo={Logo} />
      <Hero2 />
      <About2 />
      <PartnersSecion />
      <ServiceSection />
      <ProjectSection2 />
      <FunFact />
      <TeamSection />
      <TestimonialSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage2;
