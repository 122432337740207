import React from "react";
import { Link } from "react-router-dom";
import About from "../../images/about/company1.jpg";

const About2 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-about-section-s2 section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img">
              <img src={About} alt="" />
              <div className="images-text">
                <h3>12 Years Of Experience</h3>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content">
              <div className="wpo-section-title">
                <span>ABOUT OUR COMPANY!</span>
                <h2>We Are Expert For Powerful Service.</h2>
              </div>
              <div className="wpo-about-text">
                <p>
                  Lisha delivers top-notch fabrication services with precision
                  and expertise. From custom metalwork to advanced laser
                  cutting, we bring your projects to life with unmatched quality
                  and efficiency. Trust Lisha for powerful solutions that exceed
                  your expectations.
                </p>
                <ul>
                  <li>Enhancing Project Precision</li>
                  <li>Empowering Your Vision</li>
                  <li>Boosting Manufacturing Efficiency</li>
                </ul>
                <Link
                  onClick={ClickHandler}
                  className="theme-btn color-9"
                  to="/about"
                >
                  Discover More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About2;
