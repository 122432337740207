import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
import Services from "../../api/Services";
import ServiceSidebar from "./sidebar";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logolish.svg";
const ServiceSinglePage = (props) => {
  const { slug } = useParams();

  const serviceDetails = Services.find((item) => item.slug === slug);

  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <PageTitle pageTitle={serviceDetails.title} pagesub={"Service"} />
      <div className="wpo-service-single-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="wpo-service-single-wrap">
                <div className="wpo-service-single-item">
                  <div className="wpo-service-single-main-img">
                    <img src={serviceDetails.sSImg} alt="eimg" />
                  </div>
                  <div className="wpo-service-single-title">
                    <h3>{serviceDetails.slug} Engineering</h3>
                  </div>
                  <p>{serviceDetails.longContent}</p>
                  <p>{serviceDetails.smallContent}</p>
                  <div className="row mt-4">
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <img src={serviceDetails.support1} alt="eimg" />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <img src={serviceDetails.support2} alt="eimg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wpo-service-single-item list-widget">
                  <div className="wpo-service-single-title">
                    <h3>Our Capabilities</h3>
                  </div>
                  <p>{serviceDetails.capabilityDescriptions}</p>
                  <ul>
                    {serviceDetails.capabilityPoints.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
                <div className="wpo-service-single-item">
                  <div className="wpo-service-single-title">
                    <h3>Our approach</h3>
                  </div>
                  <p>{serviceDetails.approach}</p>
                </div>
                <div className="wpo-service-single-item list-widget">
                  <div className="wpo-service-single-title">
                    <h3>Our Work Process</h3>
                  </div>
                  <ul>
                    {serviceDetails.process.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <ServiceSidebar />
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServiceSinglePage;
