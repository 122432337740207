import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from "../SectionTitle/SectionTitle";

import image1 from "../../images/testimonial/1.jpg";
import image2 from "../../images/testimonial/2.jpg";
import image3 from "../../images/testimonial/3.jpg";

import Shape1 from "../../images/testimonial/shape-1.png";
import Shape2 from "../../images/testimonial/shape-2.png";
import Shape3 from "../../images/testimonial/shape.svg";

const TestimonialData = [
  {
    id: "01",
    text: "Lisha Engineering has been a game-changer for our fabrication needs. Their precision in metal cutting and reliable delivery have consistently exceeded our expectations. We appreciate their attention to detail and commitment to quality.",
    author: "Suresh Kumar",
    position: "Operations Manager, Chennai Metalworks",
    image: image1,
  },
  {
    id: "02",
    text: "The electroplating services provided by Lisha Engineering are top-notch. Their use of advanced technology ensures a flawless finish, and their team’s expertise is evident in every project. Highly recommended for any electroplating needs!",
    author: "Ravi Sharma",
    position: "Chief Engineer, Mumbai Electroplating Solutions",
    image: image2,
  },
  {
    id: "03",
    text: "Working with Lisha Engineering on our piping and skidding projects has been a pleasure. Their innovative approach and efficient solutions have significantly improved our operational efficiency. Their professionalism and quality of work are unmatched.",
    author: "Anil Rao",
    position: "Piping Specialist, Hyderabad Utilities",
    image: image3,
  },
  {
    id: "04",
    text: "Lisha Engineering’s metal bending services are exceptional. They deliver precise and reliable results, even for the most complex designs. Their dedication to meeting our specifications and deadlines is truly commendable.",
    author: "Nisha Joshi",
    position: "Lead Bending Specialist, Pune Fabrications",
    image: image1,
  },
  {
    id: "05",
    text: "Lisha Engineering has become our go-to partner for metal fabrication. Their advanced techniques and consistent quality make them stand out in the industry. We trust them to deliver high-quality components for all our projects.",
    author: "Amit Patel",
    position: "Lead Fabrication Engineer, Bengaluru Structures",
    image: image1,
  },
];

const TestimonialSection = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  return (
    <section className="wpo-testimonial-section section-padding">
      <div className="shape-1">
        <span></span>
        <img src={Shape1} alt="" />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col col-lg-8 col-12">
            <SectionTitle subtitle="TESTIMONIAl" title="What Client Say?" />
          </div>
        </div>
        <div className="wpo-testimonial-wrap">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="wpo-testimonial-laft">
                <ul className="slider-for">
                  <Slider
                    asNavFor={nav2}
                    ref={(slider1) => setNav1(slider1)}
                    arrows={false}
                    fade={true}
                  >
                    {TestimonialData.map((testimonial, index) => (
                      <li key={index}>
                        <img src={testimonial.image} alt="" />
                      </li>
                    ))}
                  </Slider>
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="wpo-testimonial-right slider-nav">
                <Slider
                  asNavFor={nav1}
                  ref={(slider2) => setNav2(slider2)}
                  slidesToShow={1}
                  swipeToSlide={true}
                  focusOnSelect={true}
                >
                  {TestimonialData.map((testimonial, index) => (
                    <div className="wpo-testimonial-content" key={index}>
                      <div className="shape">
                        <img src={Shape3} alt="" />
                      </div>
                      <p>{testimonial.text}</p>
                      <h2>{testimonial.author}</h2>
                      <span>{testimonial.position}</span>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shape-2">
        <span></span>
        <img src={Shape2} alt="" />
      </div>
    </section>
  );
};

export default TestimonialSection;
